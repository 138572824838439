import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Row, Col } from "react-bootstrap"
import Carousel from "react-bootstrap/Carousel"
import Button from "react-bootstrap/Button"
import Image from "react-bootstrap/Image"


const Hero = () => {

  const data = useStaticQuery(graphql`
  query CloudinaryHeroImages {
      skyline: cloudinaryMedia(public_id: { eq: "star-caps-hero-images/houston-skyline_cu62vg" }) {
        secure_url
      }
      inventory: cloudinaryMedia(public_id: { eq: "star-caps-hero-images/inventory_o0dz9a" }) {
        secure_url 
      }
      mask: cloudinaryMedia(public_id: { eq: "star-caps-hero-images/mask-with-bag_dsgwyu" }) {
        secure_url
      }
    }
  `)

  return (
    <>
      <Row>
        <Col className="mb-3">
          <Carousel>
            <Carousel.Item>
              <Image
                fluid
                src={data.skyline.secure_url}
                alt="Houston downtown skyline"
              />
              <Carousel.Caption className="my-4 align-items-center justify-content-center" style={{ backgroundColor: "rgba(0,0,0, 0.5)" }}>
                <h3 className="carousel-h3">Hat retail/wholesale and custom designs in Houston, TX</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image
                fluid
                src={data.inventory.secure_url}
                alt="Hats on shelves"
              />
              <Carousel.Caption className="my-4 align-items-center justify-content-center" style={{ backgroundColor: "rgba(0,0,0, 0.5)" }}>
                <h3 className="carousel-h3">Check Out Our Hats</h3>
                <Button id="carousel-button" variant="outline-light" href="/products">Browse Products</Button>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
    </>
  )
}


export default Hero;
