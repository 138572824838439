import React from "react";
import { Col, Row } from "react-bootstrap"


const Map = () => {

    return (
        <div>
            <Row className="mx-auto">
                <Col md={6}>
                    <iframe
                        title="Map of Star Caps"
                        width="100%"
                        height="350"
                        frameBorder="0"
                        style={{ border: "0" }}
                        src={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJU41-jP_CQIYR-QTC0x5Berc&key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`}>
                    </iframe>
                </Col>
                <Col md={6} className="text-center my-auto">

                    <h5>Address:</h5>
                    <p className="lead">
                        5710 Fondren Rd
                            <br />
                                Suite A
                            <br />
                                Houston, TX 77036
                            </p>

                    <h5>
                        Hours:
                    </h5>
                    <p className="lead">
                    Tuesday - Friday: 10 AM - 5 PM
                    <br />
                    Saturday: 10 AM - 4 PM
                    <br />
                    Closed Sundays &amp; Mondays
                    </p>
                </Col>
            </Row>
        </div >
    )
}

export default Map



