import React from "react"
import Layout from "../components/layout"
import Services from "../components/services"
import Map from "../components/map"
import SEO from "../components/seo"
import Hero from "../components/hero"
// import InfoFeature from "../components/alert"

const IndexPage = () => (
  <Layout>
    {/* <InfoFeature/> */}
    <Hero />
    <SEO title="Home" />
    <h1 className="mt-5 mb-3">OUR SERVICES</h1>
    <p className="lead">Many orders can be completed in as little as one day. <a href="tel:832-630-0427" rel="nofollow">Call</a> or <a href="mailto:starcapshouston@gmail.com">email</a> us to request a quote or for assistance with any questions.</p>
    <Services />
    <h1 className="mt-5 mb-3">FIND US</h1>
    <p className="lead">We're conveniently located on Fondren Rd near the Westpark Tollway. Come visit us today!</p>
    <Map />
  </Layout>
)

export default IndexPage
