import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap"
import Img from "gatsby-image"


const Services = () => {
  const data = useStaticQuery(imageQuery)

  return (
    <>
      <Row>
        <Col lg={4} className="d-flex mb-3">
          <Card className="p-4 shadow-sm">
            <Img
              alt="basket"
              fluid={data.basket.childImageSharp.fluid}
              style={{ margin: "0 auto", width: "100%" }}
            />
            <Card.Body className="flex-fill">
              <Card.Title>Retail/Wholesale</Card.Title>
              <Card.Text>We carry a wide variety of hats in the latest trends and the highest quality. With over a thousand products available in store, you're bound to find the style or color that you're looking for.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className="d-flex mb-3">
          <Card className="p-4 shadow-sm">
            <Img
              alt="sewing machine"
              fluid={data.sewing.childImageSharp.fluid}
              style={{ margin: "0 auto", width: "100%" }}
              imgStyle={{}} />
            <Card.Body className="flex-fill">
              <Card.Title>Custom Embroidery</Card.Title>
              <Card.Text>We can embroider your custom design to a wide range of fabrics and apparel to add a personalized, professional, and customized touch.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className="d-flex mb-3">
          <Card className="p-4 shadow-sm">
            <Img
              alt="iron"
              fluid={data.iron.childImageSharp.fluid}
              style={{ margin: "0 auto", width: "100%" }}
            />
            <Card.Body className="flex-fill">
              <Card.Title>Custom Vinyl Heat Transfer</Card.Title>
              <Card.Text>Created for use on nearly any garment type, vinyl is another versatile and popular option for customization. We have a large variety of finishes available for your customization needs.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Services

export const imageQuery = graphql`
query {
    basket: file(relativePath: {eq: "icons8-shopping-basket-100.png"}) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    sewing: file(relativePath: {eq: "icons8-sewing-machine-100.png"}) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    iron: file(relativePath: {eq: "icons8-iron-100.png"}) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
  `